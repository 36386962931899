/* Append to the existing MorganLewisJavaScript object if existing. If not, instantiate a new object. */
if (typeof MLCV === "undefined") {
    MLCV = [];
}

MLCV.WebAnalytics = new WebAnalytics();

/****************************************************************************
WebAnalytics
 *******/
function WebAnalytics() {
    var WebAnalytics = this;

    WebAnalytics.GA = {
        eventCategory: "Videos",
        eventAction: "Click",
        eventLabel: window.location.href,

        EnableVideoClickTracking: function () {
            jQuery('.ml-video-thumb-overlay, .ml-overlay-wrapper, .stacked-media-module-overlay-wrapper').click(function (event) {
                WebAnalytics.GA.TrackMediaClick(this);
            });
        },

        TrackMediaClick: function (category, label) {
            var eCategory = (category) ? category : WebAnalytics.GA.eventCategory;
            var eAction = "Click";
            var eLabel = (label) ? label : WebAnalytics.GA.eventLabel;
            var videoUrl, audioUrl;
            var mediaType, mediaSource;

            if (category !== "Videos" && category !== "Audios") {
                mediaType = $(category).closest('[data-mediatype]').data();
                mediaSource = $(category).closest('[data-medialink]').data();

                if (mediaType) {
                    eLabel = mediaSource.medialink;
                    if (mediaType.mediatype === 'video') {
                        eCategory = "Videos";
                    } else {                       
                        eCategory = "Audios";
                    }
                } else {
                    mediaType = $(category).closest('[data-media-type]').data();
                    mediaSource = $(category).closest('[data-media-src]').data();

                    if (mediaType) {
                        eLabel = mediaSource.mediaSrc;
                        if (mediaType.mediaType === 'video') {
                            eCategory = "Videos";
                        } else {
                            eCategory = "Audios";
                        }
                    }
                    else {
                        videoUrl = $(category).closest('[data-video-url]').data();
                        audioUrl = $(category).closest('[data-audio-url]').data();

                        if (videoUrl) {
                            eLabel = videoUrl.videoUrl;
                            eCategory = "Videos";
                        } else {
                            eLabel = audioUrl.audioUrl;
                            eCategory = "Audios";
                        }
                    }
                }
            }

            eLabel = eLabel.toLowerCase();
            if (typeof ga !== "undefined") {
                ga('send', {
                    hitType: 'event',
                    eventCategory: eCategory,
                    eventAction: eAction,
                    eventLabel: eLabel
                });
            }
        },

        TrackMediaPlay: function (category, label) {
            var eCategory = (category) ? category : WebAnalytics.GA.eventCategory;
            var eAction = "Play";
            var eLabel = (label) ? label : WebAnalytics.GA.eventLabel;

            if (category !== "Videos" && category !== "Audios") {
                var nodeName = category.nodeName;
            }

            if (nodeName === "VIDEO") {
                eCategory = "Videos";
            } else {
                eCategory = "Audios";
            }

            eLabel = $(category).find('source').attr('src');
            eLabel = eLabel.toLowerCase();

            if (typeof ga !== "undefined") {
                ga('send', {
                    hitType: 'event',
                    eventCategory: eCategory,
                    eventAction: eAction,
                    eventLabel: eLabel
                });
            }
        }

    };
}

/****************************************************************************
DOM Ready
 *******/
jQuery(document).ready(function () {
    MLCV.WebAnalytics.GA.EnableVideoClickTracking();
});

